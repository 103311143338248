// extracted by mini-css-extract-plugin
export var benefits = "get-started-module--benefits--n5rtG";
export var column = "get-started-module--column--1pxTl";
export var columnInner = "get-started-module--columnInner--YtqIT";
export var desktopImage = "get-started-module--desktopImage--ZmoP2";
export var formWrapper = "get-started-module--formWrapper--gABVu";
export var images = "get-started-module--images--4yUoH";
export var layout = "get-started-module--layout--f77a6";
export var logo = "get-started-module--logo---PMRt";
export var logoWrapper = "get-started-module--logoWrapper--lJIlc";
export var phoneImage = "get-started-module--phoneImage--opOHs";
export var signIn = "get-started-module--signIn--ftN03";