import * as React from 'react';
import { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';
import { useMixpanel } from 'gatsby-plugin-mixpanel';
import { StaticImage } from 'gatsby-plugin-image';

import { firebaseConfig } from '../firebase.config';

import Meta from '../components/meta';
import {
  GetStartedPageTitle,
  GetStartedSignIn,
  GetStartedBenefits, GetStarted,
} from '../localisation';
import { Url } from '../url';

import * as Text from '../localisation';

import Logo from '../images/logo.svg';

import * as classes from './get-started.module.css';

const uiConfig = {
  signInSuccessUrl: '/app/',
  signInOptions: [
    // Leave the lines as is for the providers you want to offer your users.
    firebase.auth.EmailAuthProvider.PROVIDER_ID,
    firebase.auth.GoogleAuthProvider.PROVIDER_ID,
    'apple.com',
    // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
    // firebase.auth.GithubAuthProvider.PROVIDER_ID,
    // firebase.auth.EmailAuthProvider.PROVIDER_ID,
    // firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    // firebaseui.auth.AnonymousAuthProvider.PROVIDER_ID
  ],
  // tosUrl and privacyPolicyUrl accept either url string or a callback
  // function.
  // Terms of service url/callback.
  tosUrl: '/terms',
  // Privacy policy url/callback.
  privacyPolicyUrl: '/privacy',
};

function SignupPage() {
  const mixpanel = useMixpanel();

  useEffect(() => {
    firebase.initializeApp(firebaseConfig);

    const ui = new firebaseui.auth.AuthUI(firebase.auth());

    ui.start(
      '#firebase-ui',
      {
        ...uiConfig,
        callbacks: {
          signInFailure(error: firebaseui.auth.AuthUIError): Promise<void> | void {
            mixpanel.track('sign_in_error', { code: error.code, message: error.message });
          },
          uiShown() {
            mixpanel.track('sign_in_ui_shown');
          }
        }
      }
    );

    return () => {
      ui.delete();
    };
  }, []);

  return (
    <div className={classes.layout}>
      <Meta
        title={GetStartedPageTitle}
        url={Url.GetStarted}
      />

      <div className={classes.column}>
        <div className={classes.columnInner}>
          <ul className={classes.benefits}>
            {GetStartedBenefits.map((benefit, index) => (
              <li key={index}>
                {benefit}
              </li>
            ))}
          </ul>

          <div className={classes.images}>
            <StaticImage
              src="../images/get-started-desktop.png"
              className={classes.desktopImage}
              alt={Text.GetStarted}
              imgStyle={{
                objectFit: "contain",
              }}
            />

            <StaticImage
              src="../images/get-started-phone.png"
              className={classes.phoneImage}
              alt={Text.GetStarted}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
        </div>
      </div>

      <div className={classes.column}>
        <div className={classes.columnInner}>
          <div>
            <div className={classes.logoWrapper}>
              <a href="/">
                <img src={Logo} alt="emery.to" className={classes.logo} />
              </a>
            </div>

            <div className={classes.formWrapper}>
              <div id="firebase-ui" />
            </div>

            <div className={classes.signIn}>
              {GetStartedSignIn}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
